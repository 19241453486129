import { template as template_e41a176fa287440e8c2a44af75cfebb3 } from "@ember/template-compiler";
const FKLabel = template_e41a176fa287440e8c2a44af75cfebb3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
