import { template as template_62e8a2d5f85c4979bcb8bf8e881bd04d } from "@ember/template-compiler";
const WelcomeHeader = template_62e8a2d5f85c4979bcb8bf8e881bd04d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
