import { template as template_60242a3f97534f049b7fa3326e298d75 } from "@ember/template-compiler";
const FKControlMenuContainer = template_60242a3f97534f049b7fa3326e298d75(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
