import { template as template_3b4fdf139d66467fbfd0a431c67caf82 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3b4fdf139d66467fbfd0a431c67caf82(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
